import { ErrorBoundary, Suspense } from "solid-js";
import ClientOnlyComponent, {
  ClientComponent,
} from "~/client_only_components/client_component";

import { HubbleError } from "~/components/error";
import { GiftRoot } from "~/components/gifting/gift_root";
import { GiftDetail } from "~/server/types/gift";
import { formatDateNumeric } from "~/utils/date";
import { captureErrorInSentry } from "~/utils/third_party/sentry";

export default function GiftPreview() {
  return (
    <ErrorBoundary
      fallback={(err) => {
        captureErrorInSentry(err);
        return <HubbleError errorMessage={err.message} />;
      }}
    >
      <Suspense>
        <script src="https://unpkg.com/@lottiefiles/lottie-player@0.3.0/dist/lottie-player.js"></script>
        <ClientOnlyComponent component={ClientComponent.SnackbarHost} />
        <GiftRoot
          brand={sampleResponse.brand}
          voucher={sampleResponse.voucher}
          amount={sampleResponse.voucher.amount}
          description={sampleResponse.description}
          isPreview={true}
          showGuidelines={false}
          defaultEventProps={{
            giftMode: "APP",
            isPreview: true,
          }}
        />
      </Suspense>
    </ErrorBoundary>
  );
}

const sampleResponse = {
  giftingKey: "testing",
  description: "You have been gifted endless choices of products",
  senderName: "Someone",
  voucher: {
    redemptionType: "CARD_AND_PIN_NO_SECURED",
    cardNumber: "1111222233334444",
    pin: "123456",
    amount: 5000,
    expiryDate: formatDateNumeric(
      new Date(new Date().setFullYear(new Date().getFullYear() + 1))
    ),
  },
  brand: {
    brandId: "01GMAVS2CHXR0XP1BZSTA9A44K",
    brandKey: "amazon",
    heroImageUrl:
      "https://assets.myhubble.money/brand-assets/hero-images-wp/amazon-hero.webp",
    deeplink: "https://google.com",
    tncResId: "voucher-tnc/amazon.md",
    brandName: "Amazon",
  },
} as GiftDetail;
